<template>
  <InfoWraper>
    <!-- <Message />
    <Notification />
    <Settings />
    <Support /> -->

    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon style="width: 150px">
            <div class="user-dropdwon">
              <!-- <figure class="user-dropdwon__info"> -->
              <!-- <img :src="require('../../../static/img/avatar/chat-auth.png')" alt="" /> -->
              <figcaption>
                <sdHeading as="h5">{{
                  userData.first_name + " " + userData.last_name
                }}</sdHeading>
                <!-- <p>Customer </p> -->
              </figcaption>
              <!-- </figure> -->
              <!-- <ul class="user-dropdwon__links">
                <li>
                  <router-link to="/account"><sdFeatherIcons type="dollar-sign" />Billing</router-link>
                </li>
              </ul> -->
              <a @click="Profile" class="user-dropdwon__bottomAction" href="#">
                <UserOutlined /> Profile
              </a>
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#">
                <LogoutOutlined /> Sign Out
              </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <img :src="require('../../../static/img/user.png')" alt="" />
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import Support from "./Support";
import Settings from "./Settings";
import Notification from "./Notification";
import Message from "./Message";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons-vue";
import Cookies from "js-cookie";

export default {
  name: "AuthInfo",
  components: {
    InfoWraper,
    NavAuth,
    UserDropDwon,
    Support,
    Settings,
    Notification,
    Message,
    LogoutOutlined,
    UserOutlined,
  },
  data() {
    return {
      flag: "english",
    };
  },
  setup() {
    const { dispatch } = useStore();
    const { push } = useRouter();
    // console.log(userData.id)
    const userData = JSON.parse(Cookies.get("user"));

    //  console.log('from auth')
    //  console.log(userData.id)

    const SignOut = (e) => {
      e.preventDefault();
      // push('/auth/login');
      dispatch("logOut");
      window.location.href = "https://my.getbetterdoctor.com/";
    };
    const Profile = (e) => {
      e.preventDefault();
      push("/account");
    };

    return {
      SignOut,
      Profile,
      userData,
    };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
};
</script>

<style>
@media (max-width: 667px) {
  .ant-popover-inner {
    width: 50%;
    float: right;
  }
}
</style>
