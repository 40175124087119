
<template>
<a-row :gutter="25">
    <a-col :lg="16" :xl="23" :md="10" :xs="24">
   <div class="white-box"  style=" background-color: black">   
       <div class="" v-click-outside="closeModal">
        <button class="mt-1 px-2 focus:outline-none" style="float: right; background:none; border:none; color:white;" @click="closeModal">
        <!-- <sdFeatherIcons  style="font-weight: 100px" type="x"  /> -->
        <font-awesome-icon icon="times" />
        </button>
        <div   class="text-left pb-5 px-5 ">
            <h1 class="text-3xl font-bold mb-3" style="color:white; font-weight: bold, margin-bottom: 6px">Need help?</h1>
            <p class="text-base font-normal mb-1" style=" font-size: 16px ">
            <font-awesome-icon  style="color:white" icon="envelope" />
            &nbsp;<span style="color:white">Email us anytime at:&nbsp;
            <a class="text-teal-400 underline" style="color:#1BABA8" href="mailto:support@getbetterdoctor.com">support@getbetterdoctor.com</a></span>
            </p>
            <p class="text-base font-normal mb-3" style="font-size: 16px">
                <font-awesome-icon style="color:white" icon="phone" />&nbsp;<span style="color:white">Call or text us at:&nbsp;
              <br/>      <a class="text-teal-400 underline" style="color:#1BABA8" href="tel:+1 (855) 785-1636)">+1 (855) 785-1636</a>
                </span>
            </p>
           
            <p class="text-xs">(Mon – Fri: 9:30am - 9pm EST). <br/> Message and data rates may apply.
            </p>
        </div>
      </div>
    </div> 
    </a-col>
</a-row>
</template>
<script>
export default {
    mounted() {
        console.log('hello')
    },
    methods: {
        closeModal() {
            this.$emit('close');
        }
    }
}
</script>
<style scoped>
.white-box{
    position: absolute;
    right: 24px;
    width: 300px;
    z-index: 1000;
     line-height: 32px;
            border-radius: 15px;
}
.text-xs{
    color:white;
     font-size:15px;
      font-weight:bold;
}
@media screen and (max-width:667px) {
    .white-box{
        
 position: absolute;
    right: -9px; 
    width: 280px;
    padding: 23px;
    z-index: 100;
    top: 7%;
        line-height: 32px;
            border-radius: 15px;


    }
}
@media (min-width: 668px )and (max-width: 1023px){
    .white-box{
        
            position: absolute;
    right: 0px;
    width: 00px;
    z-index: 100;
    top: 55%;
     line-height: 32px;
            border-radius: 15px;
    }
}
@media (min-width: 1023px )and (max-width: 1700px){
    .white-box{
        
            position: absolute;
    right: 0px;
    width: 300px;
    padding: 20px;
    z-index: 100;
    top: 55%;
     line-height: 32px;
            border-radius: 15px;
    }
}

@media (min-width: 1700px )and (max-width: 2700px){
    .white-box{
        
    right: 24px;
    width: 367px;
    /* padding: 16px; */
    padding-left: 31px;
    padding-top: 20px;
    z-index: 100;
    
    line-height: 32px;

    /* top: 7%; */
    border-radius: 15px;
    padding-right: 18px;
    }
}
</style>