<!-- eslint-disable prettier/prettier -->
<template>
  <Div :darkMode="darkMode" :theme="theme">
    <Layout class="layout">
      <Header
        :style="{
          position: 'fixed',
          width: '100%',
          top: 0,
          [!rtl ? 'left' : 'right']: 0,
        }"
      >
        <a-row class="header">
          <a-col
            :lg="!topMenu ? 4 : 3"
            :sm="6"
            :xs="12"
            :xl="12"
            class="align-center-v navbar-brand"
          >
            <sdButton
              v-if="!topMenu || innerWidth <= 991"
              @click="toggleCollapsed"
              type="white"
            >
              <img
                :src="
                  require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)
                "
                alt="menu"
              />
            </sdButton>

            <router-link
              :class="
                topMenu && innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'
              "
              to="/"
            >
              <!-- <imghttps://migrationade.com/message.svg
                :src="!darkMode ? require(`../static/img/Logo_Dark.svg`) : require(`../static/img/Logo_white.png`)"
                alt="logo"
              /> -->
              <img
                :src="
                  !darkMode
                    ? require(`../static/img/logo-full.png`)
                    : require(`../static/img/logo-full.png`)
                "
                alt="logo"
                style="margin-left: 25px"
              />
            </router-link>
          </a-col>
          <!-- <a-col :lg="!topMenu ? 14 : 15" :md="8" :sm="0" :xs="0">
            <TopMenu v-if="topMenu && innerWidth > 991" />
            <HeaderSearch v-else />
          </a-col> -->
          <!-- 
          <a-col :lg="6" :md="10" :sm="0" :xs="0">
            <TopMenuSearch v-if="topMenu && innerWidth > 991">
              <div class="top-right-wrap d-flex">
                <a
                  :class="`${activeSearch ? 'search-toggle active' : 'search-toggle'}`"
                  @click="
                    () => {
                      toggleSearch();
                    }
                  "
                  href="#"
                >
                  <sdFeatherIcons type="search" />
                  <sdFeatherIcons type="x" />
                </a>
                <div :class="`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`">
                  <form action="">
                    <span class="search-icon">
                      <sdFeatherIcons type="search" />
                    </span>
                    <input type="text" name="search" />
                  </form>
                </div>
                <AuthInfo />
              </div>
            </TopMenuSearch>
            <AuthInfo v-else />
          </a-col> -->

          <a-col
            :lg="!topMenu ? 4 : 3"
            :sm="6"
            :xs="12"
            :xl="12"
            class="navbar-brand left"
          >
            <div class="text-grey-dim font-bold relative help">
              <a
                class="cursor-pointer hover:text-primary"
                @click="openDropDownNotification"
                title="Need Help?"
                ><strong>
                  <img
                    v-if="!isRead"
                    src="../static/img/bell/unread.png"
                    style="height: 2rem"
                  />
                  <img
                    v-else
                    src="../static/img/bell/read.png"
                    style="height: 2rem"
                  /> </strong
              ></a>
              <NotificationDropDown
                v-if="showDropDownNotification"
                @close="
                  showDropDownNotification = false;
                  $forceUpdate();
                "
              />
            </div>
            <div class="text-grey-dim font-bold relative help">
              <a
                class="cursor-pointer hover:text-primary"
                @click="openDropDown"
                title="Need Help?"
                ><strong>
                  <img src="../static/img/help.png" style="height: 2rem" /></strong
              ></a>
              <HelpDropDown
                v-if="showDropDown"
                @close="
                  showDropDown = false;
                  $forceUpdate();
                "
              />
            </div>
            <div class="vl"></div>
            <AuthInfo />
            <!-- </div> -->
          </a-col>
          <a-col :style="{ position: 'static' }" :md="0" :sm="18" :xs="12">
            <div class="mobile-action">
              <!-- <a class="btn-search" @click="handleSearchHide(searchHide)" href="#">
                <sdFeatherIcons type="search" v-if="searchHide" />
                <sdFeatherIcons type="x" v-else />
              </a> -->
              <a class="btn-auth">
                <!-- <sdFeatherIcons type="more-vertical" /> -->
                <!-- <AuthInfo :rtl="rtl" /> -->
              </a>
            </div>
          </a-col>
        </a-row>
      </Header>
      <div class="header-more">
        <a-row>
          <a-col :md="0" :sm="24" :xs="24">
            <div class="small-screen-headerRight">
              <SmallScreenSearch :hide="searchHide" :darkMode="darkMode">
                <HeaderSearch />
              </SmallScreenSearch>
              <SmallScreenAuthInfo :hide="hide" :darkMode="darkMode">
                <AuthInfo :rtl="rtl" />
              </SmallScreenAuthInfo>
            </div>
          </a-col>
        </a-row>
      </div>
      <Layout>
        <template v-if="!topMenu || innerWidth <= 991">
          <Sider
            :width="280"
            :style="{
              margin: '63px 0 0 0',
              padding: '15px 15px 155px 15px',
              overflowY: 'auto',
              height: '100%',

              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"
          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <!-- <p class="sidebar-nav-title">MAIN MENU</p> -->
              <SidebarItems
                :toggleCollapsed="toggleCollapsedMobile"
                :topMenu="topMenu"
                :rtl="rtl"
                :darkMode="darkMode"
                :events="onEventChange"
              />
            </perfect-scrollbar>
          </Sider>
        </template>
        <Layout class="atbd-main-layout">
          <Content>
            <div>
              <Suspense>
                <template #default>
                  <router-view></router-view>
                </template>
                <template #fallback>
                  <div class="spin">
                    <a-spin />
                  </div>
                </template>
              </Suspense>
            </div>

            <Footer
              v-if="$route.name !== 'groupSingle'"
              class="admin-footer footer-mobile"
              :style="{
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                position: fixed,
                left: 0,
                bottom: 0,
                width: '100%',
                maxHeight: '50px',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
                margin: '0px !important',
                //position: 'absolute',
              }"
            >
              <a-row v-if="$route.name !== 'groupSingle'">
                <a-col :md="12" :xs="24">
                  <span class="admin-footer__copyright">2022 © Better Doctor</span>
                </a-col>
                <a-col :md="12" :xs="24">
                  <div class="admin-footer__links">
                    <!-- <router-link to="/app/social/profile/overview">Profile</router-link> -->
                    <!-- <router-link to="/app/users/team">Team</router-link> -->
                    <!-- <router-link to="/app/contact/contact-grid">Contacts</router-link> -->
                  </div>
                </a-col>
              </a-row>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Div>
</template>
<script>
import { Layout } from "ant-design-vue";
import { Div } from "./style";
import { SmallScreenSearch, SmallScreenAuthInfo, TopMenuSearch } from "./style";
import HeaderSearch from "../components/header-search/HeaderSearch";
import AuthInfo from "../components/utilities/auth-info/info";
import AsideItems from "./Aside";
import SidebarItems from "./Sidebar";
import TopMenu from "./TopMenuItems";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import HelpDropDown from "../view/authentication/HelpDropDown.vue";
import NotificationDropDown from "../view/authentication/NotificationDropDown.vue";
import axios from "axios";

const { Header, Footer, Sider, Content } = Layout;
import { theme } from "../config/theme/themeVariables";

export default {
  name: "WithAdminLayout",
  components: {
    Div,
    SidebarItems,
    Header,
    Layout,
    Footer,
    Sider,
    Content,
    HeaderSearch,
    SmallScreenSearch,
    SmallScreenAuthInfo,
    TopMenuSearch,
    AuthInfo,
    AsideItems,
    TopMenu,
    PerfectScrollbar,
    HelpDropDown,
    NotificationDropDown,
  },
  mounted() {
    console.log("hello");
    this.getNotifications();
  },
  data() {
    return {
      testvar: "123",
      isRead: null,
    };
  },
  methods: {
    async getNotifications() {
      // await axios.get('https://127.0.0.1:8000/api/notifications/check').then(res => {
      await axios.get("get-notifications-check.php").then((res) => {
        console.clear();
        console.log("res");
        this.isRead = res.data.is_read;
        console.log(this.isRead);
      });
    },
    openDropDown() {
      console.log("-----");
      console.log(this.showDropDown);
      this.showDropDown = true;
      this.$forceUpdate();
      console.log("-----");
    },
    openDropDownNotification() {
      console.log("-----");
      console.log(this.showDropDownNotification);
      this.showDropDownNotification = true;
      this.$forceUpdate();
      console.log("-----");
    },
    openNotificationIcon() {
      Notification.open({
        message: "Notification Title",
        description:
          "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
        icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
      });
    },
  },
  setup() {
    const collapsed = ref(false);
    const hide = ref(true);
    const searchHide = ref(true);
    const customizerAction = ref(false);
    const activeSearch = ref(false);

    // const store = useStore();
    const { dispatch, state } = useStore();

    console.log(state, "i am state");
    const rtl = computed(() => state.themeLayout.rtlData);
    const darkMode = false;
    const topMenu = computed(() => state.themeLayout.topMenu);
    theme.rtl = false;
    theme.topMenu = topMenu;

    collapsed.value = window.innerWidth <= 1200 && true;

    const toggleCollapsed = (e) => {
      e.preventDefault();
      collapsed.value = !collapsed.value;
    };
    const handleSearchHide = (search) => {
      searchHide.value = !search;
      hide.value = true;
    };
    const onShowHide = (h) => {
      hide.value = !h;
      searchHide.value = true;
    };
    const toggleSearch = () => {
      activeSearch.value = !activeSearch.value;
    };

    const toggleCollapsedMobile = () => {
      console.log("asdasd");
      if (innerWidth <= 990) {
        collapsed.value = !collapsed.value;
      }
    };

    const onRtlChange = () => {
      const html = document.querySelector("html");
      html.setAttribute("dir", "rtl");
      dispatch("changeRtlMode", true);
    };

    const onLtrChange = () => {
      const html = document.querySelector("html");
      html.setAttribute("dir", "ltr");
      dispatch("changeRtlMode", false);
    };

    const modeChangeDark = () => {
      dispatch("changeLayoutMode", true);
    };

    const modeChangeLight = () => {
      dispatch("changeLayoutMode", false);
    };

    const modeChangeTopNav = () => {
      dispatch("changeMenuMode", true);
    };

    const modeChangeSideNav = () => {
      dispatch("changeMenuMode", false);
    };

    const onEventChange = {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    };
    //console.log(topMenu.value);
    let aaa = "aaa";
    return {
      toggleCollapsed,
      handleSearchHide,
      toggleCollapsedMobile,
      onShowHide,
      collapsed,
      hide,
      searchHide,
      toggleSearch,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      theme,
    };
  },
};
</script>
<style>
.white-box {
  position: absolute;
  right: 24px;
  width: 300px;
  z-index: 1000;
}
.text-xs {
  color: white;
  font-size: 15px;
  font-weight: bold;
}
.ps {
  height: calc(100vh - 100px);
}
@media (max-width: 768px) {
  .footer-mobile {
    position: fixed;
  }
}
/* @media (min-width: 769px) and (max-width: 991px) {
  .footer-mobile {
    position: fixed;
  }
} */

/* .admin-footer {
  position: fixed;

} */

.left {
  justify-content: flex-end;
}
.help {
  /* margin-inline: 500px; */
  /* margin-right: 900px; */
  /* float:right;
   padding: 1%; */
  position: relative;
  margin-right: 1%;
  color: gray;
  align-items: center;
  display: flex;
}
.help a,
a:visited,
a:hover {
  color: gray;
}

@media screen and (max-width: 667px) {
  .white-box {
    position: absolute;
    right: -9px;
    width: 280px;
    padding: 23px;
    z-index: 100;
    top: 7%;
  }
  .header {
    justify-content: space-between;
  }
}
@media (min-width: 668px) and (max-width: 1023px) {
  .white-box {
    position: absolute;
    right: 0px;
    width: 200px;
    z-index: 100;
    top: 55%;
  }
  .header {
    justify-content: space-between;
  }
}
@media (min-width: 1023px) and (max-width: 1700px) {
  .white-box {
    position: absolute;
    right: 0px;
    width: 200px;
    z-index: 100;
    top: 55%;
  }
  .header {
    justify-content: space-between;
  }
}

@media (min-width: 1700px) and (max-width: 2700px) {
  .white-box {
    right: 24px;
    width: 424px;
    /* padding: 16px; */
    padding-left: 31px;
    padding-top: 20px;
    z-index: 100;
    /* top: 7%; */
    border-radius: 15px;
    padding-right: 18px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header {
    justify-content: space-between;
  }
}
.vl {
  border-right: 1.5px solid rgb(13, 179, 181);
  height: 40px;
  /* right: 50%; */
  /* margin: 1%; */
}
</style>
